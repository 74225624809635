const colorstheme = {
    black:' #000000',
    secondaryBlack:' #1e1e1e',
    tertiaryBlack:'#3c3c3c',
    mainWhite:'#ffffff',
    secondaryWhite:'#e6e7dd',
    tertiaryWhite:'#94938e',
    bronze:'#CD7F32',
    bronze1:'#B87333',
    chocolate:'#7B3F00'
  };

export default colorstheme;